import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsPageData } from 'src/app/core/models';

declare global {
  interface Window {
    hbspt: any;
  }
}

@Component({
  selector: 'formulaire-inscription-lbp',
  templateUrl: './formulaire-inscription-lbp.component.html',
  styleUrls: ['./formulaire-inscription-lbp.component.scss'],
})
export class FormLBPComponent implements OnInit {
  @Input() type: string = 'cf';
  @Input() endPoint: string = '/new-cf-form';

  config: any[] = [
    {
      region: 'na1',
      portalId: 19878746,
      formId: 'e1ba5d52-d752-44ea-a87f-b8c667bc20b3',
    },
  ];
  loading: boolean = true;
  url: string | undefined;
  cmsData: CmsPageData | undefined;
  isFormSubmitted: boolean = false;
  formType: string = '';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.config = [
      {
        region: 'na1',
        portalId: 19878746,
        formId:
          this.type == 'cf'
            ? 'ada9e3b5-4142-4e7a-887f-cc6cdc3fadc4'
            : 'e1ba5d52-d752-44ea-a87f-b8c667bc20b3',
      },
    ];
    this.formType = this.type;
    this.loading = true;
    this.activatedRoute.url.subscribe((activeUrl) => {
      this.url = activeUrl.join('/');
    });
    // setTimeout(() => {
    //   this.fetchConfig(this.endPoint);
    // }, 1500);
  }

  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        this.config.forEach((conf) => {
          window.hbspt.forms.create({
            region: conf.region,
            portalId: conf.portalId, // id du compte Hubspot
            formId: conf.formId, // id du formulaire
            target: '#hubspot-form-container-frame', // identifiant de la div qui contiendra le formulaire
            onFormSubmitted: () => {
              // si soumission de formulaire déclencher un évent
              this.isFormSubmitted = true;
              if (this.type === 'lead') {
                this.handleContactFormSubmit();
              }
            },
          });
        });
      }
    });
  }

  // fetchConfig(endPoint: string): void {
  //   fetch(endPoint)
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error(`HTTP error! Status: ${res.status}`);
  //       }
  //       return res.json();
  //     })
  //     .then((conf) => {
  //       this.config = [
  //         {
  //           region: 'na1',
  //           portalId: 19878746,
  //           formId: 'ada9e3b5-4142-4e7a-887f-cc6cdc3fadc4',
  //         },
  //       ];
  //       this.loading = false;
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       this.loading = false;
  //     });
  // }

  onFormSubmitted(): void {
    this.isFormSubmitted = true;
    if (this.type === 'lead') {
      // Call a function to handle contact form submission in Angular
      this.handleContactFormSubmit();
    }
  }

  // Add a method to handle contact form submission in Angular
  handleContactFormSubmit(): void {
    // Implement your logic here for handling form submission in Angular
  }
}
