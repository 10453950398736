import { Component } from '@angular/core';
import { StepperService } from 'src/app/core/services/stepper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  activeStep: number = 0;
  steps: string[] = ['Complétez le formulaire', 'Prendre un RDV (Optionnel)'];
  isContactFormSubmitted: boolean = false;
  currentRouteIsSignIn: boolean = false;

  constructor(private stepperService: StepperService, private router: Router) {
    this.currentRouteIsSignIn = this.router.url === '/form-cf-lbp';

    this.stepperService.activeStep$.subscribe((step) => {
      this.activeStep = step;
    });
    this.stepperService.isContactFormSubmitted$.subscribe((isSubmitted) => {
      this.isContactFormSubmitted = isSubmitted;
    });
  }

  handleContactFormSubmit() {
    this.stepperService.handleContactFormSubmit();
  }

  handleNext() {
    this.stepperService.handleNext();
  }

  reloadPage() {
    window.location.reload();
  }
}
