<svg
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  [attr.height]="size"
  [attr.width]="size"
  viewBox="0 0 12 12"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.394 11.75a.857.857 0 0 1 0-1.213l3.68-3.68H.857a.857.857 0 0 1 0-1.714h8.337L5.797 1.436A.857.857 0 1 1 7.06.278l4.715 5.143c.31.338.298.86-.026 1.185L6.606 11.75a.857.857 0 0 1-1.212 0z"
  />
</svg>
