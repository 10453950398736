import { ImageField, ParagraphField } from './prismic';

export enum ButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'light',
}

export enum SliderStyle {
  IMAGE = 'Image',
  FRAME = 'frame',
  ROUND = 'round',
}

export interface ItemLayoutMenu {
  label: string;
  anchor?: string;
  url: string;
  promotion?: boolean;
  isButton?: boolean;
  subMenuItems?: Array<SubMenuItem>;
}

export interface SubMenuItem {
  label: string;
  img: string;
  url?: string;
}

export interface LayoutMenu {
  items: Array<ItemLayoutMenu>;
}

export enum SEOMetaType {
  WEBSITE = 'website',
  ARTICLE = 'article',
}
export interface SEOData {
  title?: string | ParagraphField[];
  metaDescription?: string | ParagraphField[];
  metaImage?: string | ImageField;
  firstPublicationDate?: Date;
  lastPublicationDate?: Date;
}

export interface SliderConfig {
  style: SliderStyle;
  showArrows: boolean;
  isDraggable: boolean;
}

export interface SliderObject {
  path: string;
  alt: string;
  color: string;
  content: string;
}

export const BREAKPOINTS = {
  EXTRA_SMALL: 0,
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
  EXTRA_LARGE: 4,
};
