export enum CATALOG_FILTER_TYPE {
  CHECKBOX = 'Chekbox',
  RANGE = 'Range',
}

export enum CATALOG_VEHICLE_FILTER {
  TYPE = 'Type de véhicule',
  BRAND = 'Marque',
  MODEL = 'Modèle',
  TAG = 'Étiquette',
  COST = 'Loyer',
}

export enum CATALOG_VEHICLE_FILTER_HTTP_PARAMS {
  TYPE = 'modelType',
  BRAND = 'brand',
  MODEL = 'modelName',
  TAG = 'tag',
  COST = 'Cost',
}

export interface Parameter {
  name: string;
  httpParam: string;
  state: boolean;
}

export interface SubFilter {
  name: string;
  httpParam: string;
  state: boolean;
  parameters: Array<Parameter>;
}

export interface Filter {
  label: string;
  type: CATALOG_FILTER_TYPE;
  isCollapse: boolean | null;
  subFilters: Array<SubFilter>;
}

export interface Model {
  brand: string;
  modelName: string;
}

export interface Cost {
  minCost: number;
  maxCost: number;
}

export interface Range {
  min: number;
  max: number;
}
