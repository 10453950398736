import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import { Tracking } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private angulartics2: Angulartics2) {}

  sendEvent(action: Tracking | string, data?: any): void {
    this.angulartics2.eventTrack.next({
      action,
      properties: {
        ...data,
      },
    });
  }
}
