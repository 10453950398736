import { Component, HostListener, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscriber } from 'rxjs';

import { ItemLayoutMenu, LayoutMenu, Tracking } from 'src/app/core/models';
import { NAV } from 'src/app/core/NAV';
import { CmsService } from 'src/app/core/services/cms.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { ContactComponent } from 'src/app/shared/components/ui/modal/contact/contact.component';

@Component({
  selector: 'lxf-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class LayoutHeaderComponent implements OnDestroy {
  menu: LayoutMenu;
  subMenuActive?: ItemLayoutMenu;

  isOpen = false;
  scrollOnTop = true;
  darkMode = false;

  subscriber = new Subscriber();

  constructor(
    private translateService: TranslateService,
    private trackingService: TrackingService,
    private layoutService: LayoutService,
    private cmsService: CmsService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.menu = this.layoutService.getMenu();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        const url = data?.urlAfterRedirects.split('#')[0];

        this.darkMode = [
          NAV.WHO_ARE_WE(),
          NAV.WHO_ARE_WE_REWORK(),
          NAV.SERVICES(),
          NAV.BLOG_MAIN(),
          NAV.HIDDEN_BLOG_MAIN(),
          NAV.ERROR(),
          NAV.NOT_FOUND(),
        ].includes(url);
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.scrollOnTop = document.documentElement.scrollTop === 0;
  }

  clickItemMenu(item: ItemLayoutMenu): void {
    this.trackingService.sendEvent(
      `${Tracking.NAV_BAR_CTA}${this.translateService.instant(item.label)}`,
    );
    this.close();
  }

  openContactModal() {
    this.isOpen = false;
    this.dialog.open(ContactComponent, {
      panelClass: 'standard-modal',
    });
  }

  setSubmenuActive(itemLayoutMenu?: ItemLayoutMenu) {
    this.subMenuActive = itemLayoutMenu;
  }

  close(): void {
    this.subMenuActive = undefined;
    this.isOpen = false;
  }

  ngOnDestroy(): void {
    this.subMenuActive = undefined;
    this.subscriber.unsubscribe();
  }
}
