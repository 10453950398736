<div class="logo">
  <img
    loading="lazy"
    src="/public/img/logo.png"
    [alt]="'SHARED.LOGO' | translate"
    [ngClass]="{ 'img-dark': darkMode }"
  />
  <p
    class="subtitle"
    [ngClass]="{ 'baseline-dark': darkMode }"
    *ngIf="subTitle"
  >
    {{ subTitle }}
  </p>
</div>
