import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialButtonDirective } from './material-button/material-button.component';
import { ReplaceSpaceDirective } from './replace-space/replace-space.component';
import { StopCLickPropagationDirective } from './stop-click-propagation/stop-click-propagation.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MaterialButtonDirective,
    StopCLickPropagationDirective,
    ReplaceSpaceDirective,
  ],
  exports: [
    MaterialButtonDirective,
    StopCLickPropagationDirective,
    ReplaceSpaceDirective,
  ],
})
export class DirectiveModule {}
