import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  AfterViewInit,
  PLATFORM_ID,
  Inject,
} from '@angular/core';

import { ButtonStyle } from 'src/app/core/models/globals';

@Directive({
  selector: '[lxfMatButton]',
})
export class MaterialButtonDirective implements AfterViewInit {
  @Input('lxfMatButton') color: string | undefined;
  rippleClass: string = 'ripple-primary';
  positionnedElements: Array<{ element: HTMLElement; oldValue: string }> = [];
  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) {}

  ngAfterViewInit() {
    this.el.nativeElement.classList.add('app-btn');
    switch (this.color) {
      case ButtonStyle.PRIMARY: {
        this.el.nativeElement.classList.add('primary-btn');
        break;
      }
      case ButtonStyle.SECONDARY: {
        this.rippleClass = 'ripple-secondary';
        this.el.nativeElement.classList.add('secondary-btn');
        break;
      }
      case ButtonStyle.LIGHT: {
        this.rippleClass = 'ripple-secondary';
        this.el.nativeElement.classList.add('light-btn');
        break;
      }
      default: {
        this.el.nativeElement.classList.add('primary-btn');
        break;
      }
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.createRippleEffect(event, 'click');
  }
  @HostListener('touchstart', ['$event']) onTouchStart(
    event: TouchEvent,
  ): void {
    this.createRippleEffect(event, 'touch');
  }

  detectPostionAttribute(): void {
    var a = this.el.nativeElement;
    var els = [];
    while (a) {
      a = a.parentNode;
      if (a) {
        els.push(a);
      }
    }
    for (let element of els) {
      if (element.id !== undefined) {
        if (isPlatformBrowser(this.platformId)) {
          const position = window.getComputedStyle(element).position;
          if (position === 'fixed' || position === 'relative') {
            const currentElement = {
              element: element,
              oldValue: position,
            };
            element.style.position = 'initial';
            this.positionnedElements.push(currentElement);
          }
        }
      }
    }
  }

  resetPostionAttribute(): void {
    for (let position of this.positionnedElements) {
      position.element.style.position = '';
    }
  }

  createRippleEffect(event: MouseEvent | TouchEvent, type: string) {
    // Delete all position attribute in parents
    this.detectPostionAttribute();
    // Create Circle
    const circle = document.createElement('span');
    circle.setAttribute('id', 'rippleCircle');

    // Get circle's radius
    const diameter = Math.max(
      this.el.nativeElement.clientWidth,
      this.el.nativeElement.clientHeight,
    );
    const radius = diameter / 2;

    // Set circle's size
    circle.style.width = circle.style.height = `${diameter}px`;
    // Define mouse position and fixing circle position on it
    var offsetLeft = this.el.nativeElement.offsetLeft;
    var offsetTop = this.el.nativeElement.offsetTop;
    if (type === 'click') {
      const eventClick = event as MouseEvent;
      circle.style.left = `${eventClick.pageX - offsetLeft - radius}px`;
      circle.style.top = `${eventClick.pageY - offsetTop - radius}px`;
    } else {
      const eventTouch = event as TouchEvent;
      circle.style.left = `${
        eventTouch.touches[0].pageX - offsetLeft - radius
      }px`;
      circle.style.top = `${
        eventTouch.touches[0].pageY - offsetTop - radius
      }px`;
    }
    circle.classList.add(this.rippleClass);
    this.el.nativeElement.appendChild(circle);

    //cleaning position setup
    this.resetPostionAttribute();
    circle.animate(
      [{ transform: 'scale(0)' }, { transform: 'scale(3)' }, { opacity: 0 }],
      {
        duration: 800,
        iterations: 1,
      },
    );
    setTimeout(
      function () {
        circle.remove();
      }.bind(this),
      500,
    );
  }
}
