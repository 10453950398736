import { Injectable } from '@angular/core';

import { LayoutMenu } from 'src/app/core/models';
import { NAV } from 'src/app/core/NAV';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  getMenu(): LayoutMenu {
    return {
      items: [
        {
          label: 'LAYOUT.MENU.CATALOG',
          url: NAV.CATALOG(),
          subMenuItems: [
            {
              label: 'LAYOUT.MENU.CARS',
              img: 'particular_vehicle.svg',
              url: NAV.CATALOG_CATEGORIES('voitures-vp'),
            },
            {
              label: 'LAYOUT.MENU.COMMERCIAL_VEHICLES',
              img: 'commercial_vehicle.svg',
              url: NAV.CATALOG_CATEGORIES('utilitaires-vu'),
            },
            {
              label: 'LAYOUT.MENU.OTHER_VEHICLES',
              img: 'other_vehicles.svg',
              url: NAV.CATALOG_CATEGORIES('velos-vae'),
            },
            {
              label: "Véhicules d'occasion",
              img: 'particular_vehicle.svg',
              url: NAV.CATALOG_CATEGORIES('vehicules-occasion'),
            },
          ],
        },
        {
          label: 'LAYOUT.MENU.BLOG',
          url: NAV.BLOG_MAIN(),
        },
        {
          label: 'LAYOUT.MENU.SERVICES',
          url: NAV.SERVICES_MAIN(),
        },
        {
          label: 'LAYOUT.MENU.WHO_ARE_WE',
          url: NAV.WHO_ARE_WE(),
        },
      ],
    };
  }
}
