import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment.dev';


@Component({
  selector: 'formulaire-hubspot-meeting',
  templateUrl: './formulaire-hubspot-meeting.component.html',
  styleUrls: ['./formulaire-hubspot-meeting.component.scss'],
})
export class FormHubspotMeetingComponent implements OnInit {
  scriptSrc: string = environment.crm.frame

  ngOnInit() {
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(script);
  }
}
