import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CookiesService } from 'src/app/core/services/cookies.service';
import { fadeAnimation } from 'src/app/shared/animations';
import defaultTranslations from 'src/public/i18n/fr.json';

import { NavigationService } from './core/services/navigation.service';

// Surcharge Windows interface for tslint
declare global {
  interface Window {
    mixpanel: any;
    tC: any;
    msCrypto: any;
  }
}

@Component({
  selector: 'lxf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements AfterViewInit {
  readonly DEFAULT_LANGUAGE = 'fr';
  public removeEventRipple!: () => void;

  constructor(
    private translateService: TranslateService,
    private cookiesService: CookiesService,
    @Inject(DOCUMENT) private document: any,
    navigationService: NavigationService,
  ) {
    this.translateService.setTranslation(
      this.DEFAULT_LANGUAGE,
      defaultTranslations,
    );
    this.translateService.setDefaultLang(this.DEFAULT_LANGUAGE);
    this.translateService.use(this.DEFAULT_LANGUAGE);
  }

  ngAfterViewInit(): void {
    this.cookiesService.addCookieScript();
  }
}
