<div class="state-complete">
  <div class="wave"></div>
  <div class="check">
    <div class="icon"></div>
  </div>
  <h1>{{ 'MODAL.CONTACT.ALL_GOOD' | translate }}</h1>
  <p>{{ 'MODAL.CONTACT.MEETING_SUCCEED' | translate }}</p>
  <button [lxfMatButton] mat-dialog-close>
    {{ 'MODAL.CONTACT.FINISH' | translate }}
  </button>
</div>
