import { Component, Input } from '@angular/core';

@Component({
  selector: 'text-panel',
  templateUrl: './text-panel.component.html',
  styleUrls: ['./text-panel.component.scss'],
})
export class TextPanelComponent {
  @Input() type: string = '';
  @Input() isHidden: boolean = false;
}
