import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ButtonStyle } from 'src/app/core/models';

import { State } from '../../contact.component';
import { HubSpotComponent } from '../../../hubspot/hubspot.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lxf-modal-contact-init',
  templateUrl: './contact-init.component.html',
  styleUrls: ['./contact-init.component.scss'],
})
export class ContactInitComponent {
  buttonStyle: typeof ButtonStyle = ButtonStyle;
  state: typeof State = State;

  @Output() changeState = new EventEmitter<State>();

  test = 'contact@movivolt.fr';

  constructor(private translateService: TranslateService, private dialog: MatDialog) {}

  setState(newState: State): void {
    this.changeState.emit(newState);
  }

  getEmailLink(): string {
    return 'mailto:' + this.translateService.instant('MODAL.CONTACT.INIT.MAIL');
  }

  goToRDV(): void {      
    this.dialog.open(HubSpotComponent);
  }

  getPhoneLink(): string {
    return (
      'tel:+33' +
      this.translateService
        .instant('MODAL.CONTACT.INIT.PHONE_NUMBER')
        .replace(/\s/g, '')
        .substring(1)
    );
  }
}
