<div>
  <!-- PC -->
  <nav
    class="d-none d-xl-flex s-pc justify-content-center"
    [ngClass]="{ 's-scroll-top': scrollOnTop, 's-darkMode': darkMode }"
    aria-label="Main navigation"
  >
    <div
      class="container d-flex justify-content-end align-items-center w-100 s-pc-container"
    >
      <a routerLink="/" fragment="top" class="s-projet-name flex-grow-1">
        <div class="d-flex">
          <lxf-logo
            [darkMode]="darkMode && scrollOnTop"
            [subTitle]="'LAYOUT.LOGO.SUBTITLE' | translate"
          ></lxf-logo>
        </div>
      </a>
      <ng-container *ngFor="let item of menu.items">
        <!--WITH SUBMENU-->
        <div class="submenu-item" *ngIf="item.subMenuItems">
          {{ item.label | translate }}
          <div class="submenu-list">
            <ul>
              <ng-container *ngFor="let subMenu of item.subMenuItems">
                <li>
                  <a [href]="subMenu!.url" (click)="clickItemMenu(item)">
                    <div class="label">
                      <img
                        loading="lazy"
                        class="submenu-icon"
                        [src]="'/public/img/' + subMenu.img"
                        alt=""
                      />
                      {{ subMenu.label | translate }}
                    </div>
                    <div class="arrow"></div>
                  </a>
                </li>
              </ng-container>
              <li>
                <hr />
                <a [href]="item.url" (click)="clickItemMenu(item)">
                  <div class="label see-all">
                    {{ 'LAYOUT.MENU.SEE_ALL' | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!--WITHOUT SUBMENU-->
        <a
          [routerLink]="item.url"
          [fragment]="item.anchor"
          (click)="clickItemMenu(item)"
          *ngIf="!item.subMenuItems"
        >
          {{ item.label | translate }}
        </a>
      </ng-container>
      <button (click)="openContactModal()" [lxfMatButton]>
        {{ 'LAYOUT.MENU.CONTACT' | translate }}
      </button>
    </div>
  </nav>
</div>

<!-- MOBILE -->
<div
  class="s-navigation d-flex justify-content-between align-items-center d-xl-none"
  [ngClass]="{ 's-scroll-top': scrollOnTop, 's-darkMode': darkMode }"
>
  <input
    type="checkbox"
    class="s-navigation-checkbox d-none"
    id="navi-toggle"
    [(ngModel)]="isOpen"
    (click)="setSubmenuActive()"
  />

  <!-- NOM -->
  <a
    routerLink="/"
    fragment="top"
    class="s-projet-name"
    (click)="close()"
    *ngIf="!subMenuActive"
  >
    <lxf-logo
      [darkMode]="isOpen || (darkMode && scrollOnTop)"
      [subTitle]="'LAYOUT.LOGO.SUBTITLE' | translate"
    >
    </lxf-logo>
  </a>

  <div
    class="arrow-back"
    *ngIf="subMenuActive"
    (click)="setSubmenuActive()"
  ></div>

  <!-- HAMBURGER -->
  <label for="navi-toggle" class="s-navigation-button">
    <span class="s-navigation-icon">&nbsp;</span>
  </label>

  <nav class="s-navigation-nav" aria-label="Main navigation">
    <a
      (click)="close()"
      class="s-logo-container flex-grow-1 d-flex flex-column"
      routerLink="/"
    >
    </a>
    <ul *ngIf="menu" class="d-flex flex-column justify-content-around">
      <ng-container *ngFor="let item of menu.items">
        <li *ngIf="!item.subMenuItems">
          <a
            class="d-flex justify-content-between align-items-center"
            (click)="clickItemMenu(item)"
            [routerLink]="item.url"
            [fragment]="item.anchor"
          >
            {{ item.label | translate }}
          </a>
          <hr />
        </li>
        <li *ngIf="item.subMenuItems">
          <a
            class="d-flex justify-content-between align-items-center"
            (click)="setSubmenuActive(item)"
          >
            {{ item.label | translate }}
            <span class="arrow"></span>
          </a>
          <hr />
        </li>
      </ng-container>
      <li class="contact-button">
        <button (click)="openContactModal()" [lxfMatButton]>
          {{ 'LAYOUT.MENU.CONTACT' | translate }}
        </button>
      </li>
    </ul>
    <!--SUBMENU SYSTEM-->
    <div class="submenu-slide" [ngClass]="{ active: subMenuActive }">
      <div class="submenu-label" *ngIf="subMenuActive">
        {{ subMenuActive.label | translate }}
      </div>
      <div class="submenu-items">
        <ul>
          <ng-container *ngFor="let subMenu of subMenuActive?.subMenuItems">
            <li>
              <a [href]="subMenu.url">
                <span
                  ><img
                    loading="lazy"
                    class="submenu-icon"
                    [src]="'/public/img/' + subMenu.img"
                    alt=""
                  />{{ subMenu.label | translate }}</span
                >
              </a>
              <hr />
            </li>
          </ng-container>
          <li>
            <a [href]="subMenuActive?.url" class="see-all-mobile">
              <span
                ><img
                  loading="lazy"
                  class="submenu-icon"
                  [src]="'/public/img/see-all.svg'"
                  alt=""
                />{{ 'LAYOUT.MENU.SEE_ALL' | translate }}</span
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
