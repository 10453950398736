import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment.dev';

@Injectable()
export class SsrInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId) || !environment.production) {
      return next.handle(request);
    }
    const secureReq = request.clone({
      url: `${environment.back_ssr_internal}${request.url}`,
    });
    return next.handle(secureReq);
  }
}
