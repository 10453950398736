<svg
  [attr.height]="size"
  [attr.width]="size"
  viewBox="0 0 38 38"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle opacity=".5" cx="19" cy="19" r="19" fill="#B9C7DB" />
  <path
    d="M27.097 9.5H10.903A1.403 1.403 0 0 0 9.5 10.903v16.194a1.403 1.403 0 0 0 1.403 1.403h16.194a1.403 1.403 0 0 0 1.403-1.403V10.903A1.403 1.403 0 0 0 27.097 9.5zM15.163 25.686h-2.857v-9.074h2.857v9.074zm-1.43-10.332a1.64 1.64 0 1 1 1.65-1.638 1.618 1.618 0 0 1-1.65 1.638zm11.96 10.34h-2.856v-4.958c0-1.462-.622-1.913-1.424-1.913-.847 0-1.678.639-1.678 1.95v4.92h-2.857v-9.075h2.747v1.258h.037c.276-.558 1.242-1.512 2.716-1.512 1.594 0 3.316.946 3.316 3.717l-.002 5.613z"
    fill="#001E51"
  />
</svg>
