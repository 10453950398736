import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { ArrowComponent } from 'src/app/shared/components/ui/arrow/arrow.component';
import { BtnPaginationComponent } from 'src/app/shared/components/ui/btn-pagination/btn-pagination.component';
import { MouseComponent } from 'src/app/shared/components/ui/mouse/mouse.component';
import { QuoteComponent } from 'src/app/shared/components/ui/quote/quote.component';
import { TwoImageComponent } from 'src/app/shared/components/ui/two-image/two-image.component';
import { VehicleAutonomyComponent } from 'src/app/shared/components/ui/vehicle-autonomy/vehicle-autonomy.component';
import { PipeModule } from 'src/app/shared/pipe/pipe.module';
import { SwiperModule } from 'swiper/angular';

import { DirectiveModule } from '../../directives/directive.module';
import { ArticleDateTimeComponent } from './article-date-time/article-date-time.component';
import { BtnTagComponent } from './btn-tag/btn-tag.component';
import { CardBlogArticleComponent } from './card-blog-article/card-blog-article.component';
import { CardServiceComponent } from './card-service/card-service.component';
import { CardVehicleComponent } from './card-vehicle/card-vehicle.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FiltersListComponent } from './filters-list/filters-list.component';
import { LogoComponent } from './logo/logo.component';
import { ContactComponent } from './modal/contact/contact.component';
import { ContactCompleteComponent } from './modal/contact/state/complete/contact-complete';
import { ContactInitComponent } from './modal/contact/state/init/contact-init.component';
import { ContactMailComponent } from './modal/contact/state/mail/contact-mail.component';
import { ContactPhoneComponent } from './modal/contact/state/phone/contact-phone.component';
import { FiltersComponent } from './modal/filters/filters.component';
import { HubSpotComponent } from './modal/hubspot/hubspot.component';
import { PriceComponent } from './price/price.component';
import { QuotationAppointementLinkBtnsComponent } from './quotation-appointement-link-btns/quotation-appointement-link-btns.component';
import { SliderComponent } from './slider/slider/slider.component';
import { SocialNetworkFacebookComponent } from './social-network/facebook/facebook.component';
import { SocialNetworkLinkedinComponent } from './social-network/linkedin/linkedin.component';
import { SocialNetworkTwitterComponent } from './social-network/twitter/twitter.component';
import { SocialNetworkYoutubeComponent } from './social-network/youtube/youtube.component';
import { StepperComponent } from './stepper/stepper.component';
import { TagsComponent } from './tags/tags.component';
import { FormLBPComponent } from './formulaire-inscription-lbp/formulaire-inscription-lbp.component';
import { FormHubspotMeetingComponent } from './formulaire-hubspot-meeting/formulaire-hubspot-meeting.component';
import { TextPanelComponent } from './formulaires/text-panel/text-panel.component';
import { SubmissionFormPanelComponent } from './formulaires/submission-form-panel/submission-form-panel.component';
import { InsitactionActualites } from './_insitaction/actualites/actualites.component';
import { InsitactionCardBlogArticleComponent } from './_insitaction/card-blog-article/card-blog-article.component';
import { InsitactionConfiance } from './_insitaction/confiance/confiance.component';
import { InsitactionContacterExperts } from './_insitaction/contacter-experts/contacter-experts.component';
import { InsitactionOffresLocations } from './_insitaction/offres-locations/offres-locations.component';
import { InsitactionServicesBloc } from './_insitaction/services-bloc/services-bloc.component';
import { DevisInitComponent } from './modal/devis/state/init/devis-init.component';
import { DevisComponent } from './modal/devis/devis.component';
import { DevisCompleteComponent } from './modal/devis/state/complete/devis-complete';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    MatDialogModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    AnimateOnScrollModule,
    RouterModule,
    DirectiveModule,
    ReactiveFormsModule,
    SwiperModule,
    MatFormFieldModule,
    MatStepperModule,
    MatCheckboxModule,
    NgxSliderModule,
  ],
  declarations: [
    QuoteComponent,
    ArrowComponent,
    BtnPaginationComponent,
    MouseComponent,
    VehicleAutonomyComponent,
    TwoImageComponent,
    LogoComponent,
    SocialNetworkFacebookComponent,
    SocialNetworkTwitterComponent,
    SocialNetworkLinkedinComponent,
    StepperComponent,
    CardBlogArticleComponent,
    CardVehicleComponent,
    CardServiceComponent,
    FormLBPComponent,
    FormHubspotMeetingComponent,
    SubmissionFormPanelComponent,
    TextPanelComponent,
    TagsComponent,
    BtnTagComponent,
    ArticleDateTimeComponent,
    SocialNetworkYoutubeComponent,
    PriceComponent,
    QuotationAppointementLinkBtnsComponent,
    FilterButtonComponent,
    ContactComponent,
    DevisComponent,
    DevisInitComponent,
    DevisCompleteComponent,
    HubSpotComponent,
    FiltersListComponent,
    FiltersComponent,
    ContactInitComponent,
    ContactMailComponent,
    ContactPhoneComponent,
    ContactCompleteComponent,
    SliderComponent,
    InsitactionCardBlogArticleComponent,
    InsitactionActualites,
    InsitactionContacterExperts,
    InsitactionConfiance,
    InsitactionServicesBloc,
    InsitactionOffresLocations,
  ],
  exports: [
    QuoteComponent,
    ArrowComponent,
    BtnPaginationComponent,
    MouseComponent,
    TwoImageComponent,
    LogoComponent,
    SocialNetworkFacebookComponent,
    SocialNetworkTwitterComponent,
    SocialNetworkLinkedinComponent,
    CardBlogArticleComponent,
    CardVehicleComponent,
    StepperComponent,
    FormLBPComponent,
    FormHubspotMeetingComponent,
    SubmissionFormPanelComponent,
    TextPanelComponent,
    CardServiceComponent,
    TagsComponent,
    BtnTagComponent,
    ArticleDateTimeComponent,
    SocialNetworkYoutubeComponent,
    PriceComponent,
    QuotationAppointementLinkBtnsComponent,
    FilterButtonComponent,
    ContactComponent,
    DevisComponent,
    HubSpotComponent, 
    FiltersListComponent,
    NgxSliderModule,
    SliderComponent,
    InsitactionCardBlogArticleComponent,
    InsitactionActualites,
    InsitactionContacterExperts,
    InsitactionConfiance,
    InsitactionServicesBloc,
    InsitactionOffresLocations,
  ],
})
export class UiModule {}
