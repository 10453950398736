import { Component, Input } from '@angular/core';

@Component({
  selector: 'submission-form-panel',
  templateUrl: './submission-form-panel.component.html',
  styleUrls: ['./submission-form-panel.component.scss'],
})
export class SubmissionFormPanelComponent {
  @Input() type: string = '';
  @Input() subEvent: string = '';

  movivoltExpertPhoneNumber: string = '06 59 71 96 70';
}
