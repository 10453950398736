import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAV } from 'src/app/core/NAV';
import { RedirectComponent } from 'src/app/redirect.component';
import { StepperComponent } from './shared/components/ui/stepper/stepper.component';

const routes: Routes = [
  {
    path: 'blog',
    component: RedirectComponent,
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./features/post/post.module').then((m) => m.PostModule),
  },
  {
    path: 'catalogue',
    component: RedirectComponent,
  },
  {
    path: 'catalogue',
    loadChildren: () =>
      import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'services',
    component: RedirectComponent,
  },
  {
    path: 'form-cf-lbp',
    component: StepperComponent,
  },
  {
    path: 'form-lead-lbp',
    component: StepperComponent,
  },
  {
    path: 'service',
    loadChildren: () =>
      import('./features/post/post.module').then((m) => m.PostModule),
  },
  {
    path: 'resah',
    component: RedirectComponent,
  },
  {
    path: 'page',
    loadChildren: () =>
      import('./features/cms/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('./features/preview/preview.module').then((m) => m.PreviewModule),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./features/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/cms/cms.module').then((m) => m.CmsModule),
  },
  { path: '**', redirectTo: NAV.NOT_FOUND() },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
