import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthorNamePipe } from 'src/app/shared/pipe/author-name/author-name.pipe';
import { CmsToHtmlPipe } from 'src/app/shared/pipe/cms-to-html/cms-to-html.pipe';
import { CmsToImgPipe } from 'src/app/shared/pipe/cms-to-img/cms-to-img.pipe';
import { FieldCmsParagraphPipe } from 'src/app/shared/pipe/field-cms/field-cms.pipe';
import { IsStringPipe } from 'src/app/shared/pipe/is-string/is-string.pipe';
import { OpacityPipe } from 'src/app/shared/pipe/opacity/opacity.pipe';

import { CmsToTextPipe } from './cms-to-text/cms-to-text.pipe';
import { ToLocalizePipe } from './to-localize/to-localize.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CmsToHtmlPipe,
    CmsToImgPipe,
    CmsToTextPipe,
    OpacityPipe,
    AuthorNamePipe,
    FieldCmsParagraphPipe,
    IsStringPipe,
    ToLocalizePipe,
  ],
  exports: [
    CmsToHtmlPipe,
    CmsToImgPipe,
    CmsToTextPipe,
    OpacityPipe,
    AuthorNamePipe,
    FieldCmsParagraphPipe,
    IsStringPipe,
    ToLocalizePipe,
  ],
})
export class PipeModule {}
