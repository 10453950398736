<svg
  [attr.height]="size"
  [attr.width]="size"
  viewBox="0 0 38 38"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle opacity=".5" cx="19" cy="19" r="19" fill="#B9C7DB" />
  <path
    d="m12.05 16.63-2.77-.05c-.897-.017-1.796.017-2.676-.159-1.337-.263-1.432-1.554-1.53-2.637A17.839 17.839 0 0 1 5.246 9.2c.146-.847.719-1.353 1.604-1.408 2.99-.2 6-.176 8.983-.083.316.009.633.056.944.109 1.533.259 1.57 1.722 1.67 2.954.1 1.244.057 2.495-.132 3.73-.152 1.024-.443 1.882-1.67 1.965-1.539.108-3.041.195-4.584.168 0-.007-.008-.007-.013-.007zm-1.63-2.592 3.45-1.909c-1.162-.641-2.297-1.272-3.45-1.909v3.818z"
    fill="#001E51"
  />
</svg>
