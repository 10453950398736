import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { SsrInterceptor } from 'src/app/core/ssr.interceptor';
import { LayoutModule } from 'src/app/layout/layout.module';
import { RedirectComponent } from 'src/app/redirect.component';
import { environment } from 'src/environments/environment.dev';
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AnimateOnScrollModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({ extend: true }),
    BrowserTransferStateModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SsrInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: 'googleTagManagerId', useValue: environment.gtm },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
