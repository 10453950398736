<div class="modal-container">
  <div mat-dialog-close class="close"></div>
  <div class="contact-container">
    <ng-container *ngIf="state === State.INIT">
      <lxf-modal-contact-init (changeState)="setState($event)" @fadeIn>
      </lxf-modal-contact-init>
    </ng-container>
    <ng-container *ngIf="state === State.MAIL">
      <lxf-modal-contact-mail (changeState)="setState($event)" @fadeIn>
      </lxf-modal-contact-mail>
    </ng-container>
    <ng-container *ngIf="state === State.PHONE">
      <lxf-modal-contact-phone (changeState)="setState($event)" @fadeIn>
      </lxf-modal-contact-phone>
    </ng-container>
    <ng-container *ngIf="state === State.COMPLETE">
      <lxf-modal-contact-complete @fadeIn> </lxf-modal-contact-complete>
    </ng-container>
  </div>
</div>
