import { Component, Input } from '@angular/core';

@Component({
  selector: 'lxf-social-network-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class SocialNetworkYoutubeComponent {
  @Input() size = 38;
}
