import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HTTP } from 'src/app/core/HTTP';
import {
  CrmContactSubmission,
  CrmContactSubmissionResult,
} from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class CrmService {
  constructor(private httpClient: HttpClient) {}

  contactMailSubmission(
    data: CrmContactSubmission,
  ): Observable<CrmContactSubmissionResult> {
    return this.httpClient.post<CrmContactSubmissionResult>(
      HTTP.HUBSPOT.CONTACT_MAIL(),
      data,
    );
  }

  contactPhoneSubmission(
    data: CrmContactSubmission,
  ): Observable<CrmContactSubmissionResult> {
    return this.httpClient.post<CrmContactSubmissionResult>(
      HTTP.HUBSPOT.CONTACT_PHONE(),
      data,
    );
  }
}
