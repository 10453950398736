import { environment } from 'src/environments/environment.dev';

const { cms, back } = environment;

export const HTTP = {
  CMS: {
    REF: () => `${cms}`,
    DOCUMENT: (ref: string, id: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[at(document.id, "${id}")]]`,
    PREVIEW: (ref: string, id: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[at(document.id, "${id}")]]&fetchLinks=blog_auteur.prenom,blog_auteur.nom,blog_auteur.profil,vehicle_vp.image,blog_tag.nom`,
    PAGE: (ref: string, slug: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[at(my.page.uid, "${slug}")]]`,
    BLOG_POST: (ref: string, slug: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[at(my.blog_article.uid, "${slug}")]]&fetchLinks=blog_auteur.prenom,blog_auteur.nom,blog_auteur.profil,vehicle_vp.image,blog_tag.nom`,
    BLOG_POSTS: (ref: string, tagsFilterParam: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[any(document.type,["blog_article"])]]&q=[[any(document.tags,[${tagsFilterParam}])]]&orderings=[document.first_publication_date desc]&fetchLinks=blog_tag.nom&pageSize=100`,
    BLOG_SPOTLIGHT: (ref: string, size: number) =>
      `${cms}/documents/search?ref=${ref}&q=[[any(document.type,["blog_article"])][at(my.blog_article.a_la_une, true)]]&orderings=[document.first_publication_date desc]&pageSize=${size}&fetchLinks=blog_auteur.prenom,blog_auteur.nom,blog_auteur.profil,blog_tag.nom`,
    BLOG_MAIN_PAGE: (ref: string, size: number) =>
      `${cms}/documents/search?ref=${ref}&q=[[any(document.type,["blog_article"])][at(my.blog_article.afficher_sur_la_page_d_accueil, true)]]&orderings=[document.first_publication_date desc]&pageSize=${size}&fetchLinks=blog_auteur.prenom,blog_auteur.nom,blog_auteur.profil,blog_tag.nom`,
    BLOG_TAGS: () => `${cms}/tags`, //Temporary, wait fix on issue : https://community.prismic.io/t/api-tags-block-by-cors/9765
    SERVICES: (ref: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[any(document.type,["service"])]]&orderings=[my.service.ordre]`,
    SERVICE: (ref: string, slug: string) =>
      `${cms}/documents/search?ref=${ref}&q=[[at(my.service.uid, "${slug}")]]`,
  },
  CATALOG: {
    CATALOG_LIST: (params: string) => `${back}/catalogue` + params,
    VEHICLE_DETAIL: (id: string) => `${back}/catalogue/${id}`,
    VEHICLE_DETAIL_ALTERNATIVE: (id: string) =>
      `${back}/catalogue/${id}/alternatives`,
    VEHICLE_BRANDS: `${back}/catalogue/brands`,
    VEHICLE_COST: `${back}/catalogue/cost-range`,
    VEHICLE_MODELS: (params: string) => `${back}/catalogue/models` + params,
  },
  HUBSPOT: {
    CONTACT_MAIL: () => `${back}/hubspot/contact/mail`,
    CONTACT_PHONE: () => `${back}/hubspot/contact/phone`,
  },
};
