import {
  BaseHttp,
  ImageField,
  LinkField,
  ParagraphField,
  Slice,
} from './prismic';

export enum CmsVehicleType {
  VEHICLE_VP = 'vehicule_vp',
  VEHICLE_VUL = 'vehicule',
  VEHILCE_VA = 'VA',
}

export enum CmsSliceType {
  EN_TETE_IMAGE_A_DROITE = 'en_tete_image_a_droite',
  CONTACT = 'contact',
  CONTACT_V2 = 'contact_2',
  LEGAL_NOTICE = 'mentions_legales',
  TITLE_AND_THREE_CASCADING_BLOCKS = 'titre_et_trois_bloc_en_cascade',
  TITLE_AND_THREE_BLOCK_IMAGE_DESCRIPTION = 'slice_title_and_three_block_image_description',
  PREJUDICE = 'prejuges',
  SERVICES = 'services',
  RECHARGE = 'recharge',
  DARK_HEADER = 'dark_header',
  BLOG_SPOTLIGHT = 'blog-spotlight',
  A_PROPOS_OBJECTIF = 'a_propos_objectif',
  A_PROPOS_VALEURS = 'a_propos_valeurs',
  BRANDS = 'marques',
  AVANTAGES = 'avantages',
  HEADER_COLUMN = 'header-column',
  HEADER_ROW = 'header-row',
  BLOG_PUSH_ARTICLE = 'blog-push-article',
  CATALOG = 'catalog',
  TEXT = 'text',
  PROMOTION = 'promotion',
  TEXT_ILLUSTRATION = 'text_illustration',
  THREE_BLOCK_IMAGE = 'bloc_image_trois_texte_et_titres',
}

export interface CmsSliceEnTeteImageADroite extends Slice {
  primary: {
    titre_principal: Array<ParagraphField>;
    image_a_droite: ImageField;
  };
}

export interface CmsSliceContact extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    texte_du_bouton: Array<ParagraphField>;
    image: ImageField;
    texte_telephone: Array<ParagraphField>;
    numero_de_telephone: Array<ParagraphField>;
  };
}
export interface CmsSliceLegalNotice extends Slice {
  primary: {
    contenu: Array<ParagraphField>;
    nombre_de_paragraphe: number;
  };
}

export interface CmsSliceTitleAndThreeCascadingBlocks extends Slice {
  primary: {
    image_de_fond: ImageField;
    pre_titre: Array<ParagraphField>;
    titre: Array<ParagraphField>;
    titre_paragraph_1: Array<ParagraphField>;
    contenu_paragraph_1: Array<ParagraphField>;
    titre_paragraph_2: Array<ParagraphField>;
    contenu_paragraph_2: Array<ParagraphField>;
    titre_paragraph_3: Array<ParagraphField>;
    contenu_paragraph_3: Array<ParagraphField>;
  };
}

export interface CmsSliceTitleAndThreeBlockImageDescription extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    pre_titre: Array<ParagraphField>;
    image_gauche: ImageField;
    contact_texte: Array<ParagraphField>;
    contact_texte_bouton: Array<ParagraphField>;
    titre_niveau_3: Array<ParagraphField>;
    icone1: ImageField;
    titre1: Array<ParagraphField>;
    contenu1: Array<ParagraphField>;
    icone2: ImageField;
    titre2: Array<ParagraphField>;
    contenu2: Array<ParagraphField>;
    icone3: ImageField;
    titre3: Array<ParagraphField>;
    contenu3: Array<ParagraphField>;

    zone_1_titre_1: Array<ParagraphField>;
    zone_1_contenu_1: Array<ParagraphField>;
    zone_1_image_1: ImageField;
    zone_1_titre_2: Array<ParagraphField>;
    zone_1_contenu_2: Array<ParagraphField>;
    zone_1_image_2: ImageField;
    zone_1_titre_3: Array<ParagraphField>;
    zone_1_contenu_3: Array<ParagraphField>;
    zone_1_image_3: ImageField;
  };
  items: Array<{
    modele: Array<ParagraphField>;
    puissance: number;
  }>;
}

export interface CmsSliceRecharge extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    pre_titre: Array<ParagraphField>;
    image_gauche: ImageField;
    contact_texte: Array<ParagraphField>;
    contact_texte_bouton: Array<ParagraphField>;
    block_droite_image: ImageField;
    block_droite_texte: Array<ParagraphField>;
    texte_a_droite: Array<ParagraphField>;
    titre_partenaire: Array<ParagraphField>;
    zone_2_image: ImageField;
    zone_2_titre: Array<ParagraphField>;
    tableau_colonne_1: Array<ParagraphField>;
    tableau_colonne_2: Array<ParagraphField>;
    tableau_colonne_3: Array<ParagraphField>;
  };
  items: Array<{
    puissance: Array<ParagraphField>;
    solution: Array<ParagraphField>;
    temps_de_recharge: Array<ParagraphField>;
    logo_partenaire: ImageField;
  }>;
}

export interface CmsSliceService extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    pre_titre: Array<ParagraphField>;
  };
  items: Array<{
    image: ImageField;
    titre: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
  }>;
}

export interface CmsSlicePrejudice extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    pre_titre: Array<ParagraphField>;
    image_gauche: ImageField;
    image_droite: ImageField;
  };
  items: Array<{
    titre: Array<ParagraphField>;
    contenu: Array<ParagraphField>;
  }>;
}

export interface CmsPageData {
  data: {
    titre?: Array<ParagraphField>;
    meta_description?: Array<ParagraphField>;
    meta_image: ImageField;
    body: Array<Slice>;
  };
}

export interface CmsPageHttp extends BaseHttp {
  results: Array<CmsPageData>;
}

export interface SliceDarkHeader extends Slice {
  primary: {
    titre_slice: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    background: ImageField;
    image_du_milieu: ImageField;
  };
}

export interface SliceBlogSpotlight extends Slice {
  primary: {
    titre1: Array<ParagraphField>;
  };
}

export interface SliceAProposObjectif extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    image1: ImageField;
    image2: ImageField;
  };
  items: Array<{
    objectif: Array<ParagraphField>;
    objectif_detail: Array<ParagraphField>;
  }>;
}

export interface SliceAProposValeurs extends Slice {
  primary: {
    titre: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    titre_gauche: Array<ParagraphField>;
    image_de_droite: ImageField;
  };
  items: Array<{
    icone: ImageField;
    titre: Array<ParagraphField>;
    description: Array<ParagraphField>;
  }>;
}

export interface CmsSlicePromotion extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    image: ImageField;
    texte: Array<ParagraphField>;
    lien: LinkField;
    texte_lien: Array<ParagraphField>;
  };
}

export interface CmsSliceBrands extends Slice {
  primary: {
    titre1: Array<ParagraphField>;
  };
  items: Array<{
    logo: ImageField;
    couleur: string;
  }>;
}
export interface CmsSliceAvantages extends Slice {
  primary: {
    titre1: Array<ParagraphField>;
  };
  items: Array<{
    image: ImageField;
    sous_titre: Array<ParagraphField>;
    contenu: Array<ParagraphField>;
  }>;
}
export interface CmsSliceHeaderColumn extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    image: ImageField;
  };
}
export interface CmsSliceHeaderRow extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    image: ImageField;
    fond_ecran: boolean;
  };
}
export interface CmsSliceBlogPushArticle extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
  };
}

export interface SliceContactV2 extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    image: ImageField;
    texte_contact: Array<ParagraphField>;
  };
}

export interface CmsSliceCatalog extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    image_fond: ImageField;
  };
  items: Array<{
    icone: ImageField;
    titre2: Array<ParagraphField>;
    sous_titre: Array<ParagraphField>;
    lien: LinkField;
  }>;
}

export interface CmsSliceText extends Slice {
  primary: {
    contenu: Array<ParagraphField>;
  };
}

export interface CmsSliceTextIllustration extends Slice {
  primary: {
    titre2: Array<ParagraphField>;
    description: Array<ParagraphField>;
    image: ImageField;
  };
}

export interface CmsThreeBlockAndImage extends Slice {
  primary: {
    titre_principal: Array<ParagraphField>;
    titre2: Array<ParagraphField>;
    description: Array<ParagraphField>;
    image: ImageField;
  };
  items: Array<{
    titre_du_bloc: Array<ParagraphField>;
    contenu: Array<ParagraphField>;
  }>;
}
