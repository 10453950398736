<div class="pannel-container" [ngClass]="{ confirm: type === 'confirm' }">
  <div class="pannel">
    <ng-container *ngIf="subEvent === 'cf-submission'; else otherEvent">
      <div class="panel-title">
        <h2>Confirmation de votre inscription</h2>
      </div>
      <div class="pannel-content">
        <br />
        <p>Bonjour,</p>
        <br />
        <p>
          Votre inscription au formulaire de prescription Movivolt est bien
          enregistrée,
        </p>
        <p>vous pouvez dès aujourd'hui réaliser des prescriptions.</p>
        <p>
          Pour toute question vous pouvez contacter votre interlocuteur Movivolt
          au numéro suivant: {{ movivoltExpertPhoneNumber }}.
        </p>
        <br />
        <p>Cordialement,</p>
        <br />
        <p>L'équipe Movivolt</p>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
        "
      >
        <a routerLink="/form-cf-lbp">
          <button mat-raised-button color="primary">
            Enregistrer un nouveau contact
          </button>
        </a>
      </div>
    </ng-container>
    <ng-template #otherEvent>
      <div class="panel-title">
        <h2>Confirmation de votre demande</h2>
      </div>
      <div class="pannel-content">
        <br />
        <p>Bonjour,</p>
        <br />
        <p>
          Votre contact a bien été enregistré et a été transféré à un expert,
          celui-ci prendra contact avec lui pour l'établissement d'un devis.
        </p>
        <p>Vous serez tenu informé de l'issue du RDV.</p>
        <p>
          Dans l'intervalle et pour toute question vous pouvez contacter votre
          interlocuteur Movivolt au numéro suivant :
          {{ movivoltExpertPhoneNumber }}.
        </p>
        <br />
        <p>Cordialement,</p>
        <br />
        <p>L'équipe Movivolt</p>
      </div>
    </ng-template>
  </div>
</div>
