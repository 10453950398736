import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'lxf-modal-hubspot',
  templateUrl: './hubspot.component.html',
})
export class HubSpotComponent implements AfterViewInit {
  srcFrame = environment.crm.frame;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderExternalScript(environment.crm.script);
  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }
}
