export const environment = {
  production: true,
  mixpanel: '7953e1612af6716377cd9bc5b2547fa5',
  gtm: 'GTM-56CTQ4V',
  back: `https://admin.movivolt.fr/api/v1`,
  cms: `https://avocat.prismic.io/api/v2`, // Apache Proxy pass use this prefix for called SSR Server
  crm: {
    script:
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
    frame:
      'https://meetings.hubspot.com/expert-movivolt-pp/prod-prise-de-rdv-site-internet?embed=true',
  },
  back_ssr_internal: 'http://lxf-ug-back-site-vitrine:3001',
  hostname: 'https://www.movivolt.fr', // Same for dev, preprod and prod servers. In the future change for each environment
  csp_domain: '*.movivolt.fr',
};
