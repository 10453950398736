import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { BREAKPOINTS } from 'src/app/core/models';
import { environment } from 'src/environments/environment.dev';

const { back } = environment;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) {}

  getWindowsSize(): number {
    if (isPlatformServer(this.platformId)) {
      return 1200;
    }
    return window?.innerWidth;
  }

  getBreakPoints(): number {
    const windowsSize = this.getWindowsSize();
    if (windowsSize > 1200) {
      return BREAKPOINTS.EXTRA_LARGE;
    } else if (windowsSize >= 992) {
      return BREAKPOINTS.LARGE;
    } else if (windowsSize >= 768) {
      return BREAKPOINTS.MEDIUM;
    } else if (windowsSize >= 576) {
      return BREAKPOINTS.SMALL;
    } else {
      return BREAKPOINTS.EXTRA_SMALL;
    }
  }

  relativeToAbsoluteServerPath(relativePath: string): string {
    return back + relativePath;
  }
}
