<div>
  <div class="header-container">
    <h1>{{ 'MODAL.CONTACT.MAIL_PHONE.TITLE_RECONTACT' | translate }}</h1>
    <p>{{ 'MODAL.CONTACT.MAIL_PHONE.TITLE_COORDONNEES' | translate }}</p>
  </div>
  <div class="back-container">
    <button class="back" (click)="setState(state.INIT)">
      <div class="back-inside-container">
        <div class="arrow"></div>
        <span class="back-button-text">{{
          'SHARED.PREVIOUS' | translate
        }}</span>
      </div>
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="sendForm()">
    <div class="form-line">
      <div class="form-line-radio">
        <label>Vous êtes</label><br />
        <div>
          <input type="radio" formControlName="userType" value="professional" />
          Un professionnel<br />
        </div>
        <div>
          <input type="radio" formControlName="userType" value="individual" />
          Un particulier
        </div>
      </div>
    </div>

    <div
      class="form-line"
      *ngIf="form.get('userType')?.value === 'professional'"
    >
      <div class="form-fieldset">
        <label for="lastname">Nom de l'entreprise</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('name')?.invalid }"
          formControlName="name"
          type="text"
          class="form-control"
          id="name"
          placeholder="Nom de l'entreprise"
        />
        <div
          *ngIf="form.get('name')?.invalid && !form.get('name')?.pristine"
          class="invalid-feedback"
        >
          Le nom de l'entreprise est requis
        </div>
      </div>
      <div class="form-fieldset">
        <label for="siret">SIRET</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('siret')?.invalid }"
          formControlName="siret"
          type="text"
          class="form-control"
          id="siret"
          placeholder="SIRET"
        />
      </div>
    </div>

    <div class="form-line">
      <div class="form-fieldset">
        <label for="firstname">{{
          'MODAL.CONTACT.MAIL_PHONE.FIRSTNAME' | translate
        }}</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('firstname')?.invalid }"
          formControlName="firstname"
          type="text"
          class="form-control"
          id="firstname"
          [placeholder]="
            'MODAL.CONTACT.MAIL_PHONE.FIRSTNAME_PLACEHOLDER' | translate
          "
        />
        <div
          *ngIf="
            form.get('firstname')?.invalid && !form.get('firstname')?.pristine
          "
          class="invalid-feedback"
        >
          {{ 'MODAL.CONTACT.MAIL_PHONE.FIRSTNAME_ERROR' | translate }}
        </div>
      </div>
      <div class="form-fieldset">
        <label for="lastname">{{
          'MODAL.CONTACT.MAIL_PHONE.LASTNAME' | translate
        }}</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('lastname')?.invalid }"
          formControlName="lastname"
          type="text"
          class="form-control"
          id="lastname"
          [placeholder]="
            'MODAL.CONTACT.MAIL_PHONE.LASTNAME_PLACEHOLDER' | translate
          "
        />
        <div
          *ngIf="
            form.get('lastname')?.invalid && !form.get('lastname')?.pristine
          "
          class="invalid-feedback"
        >
          {{ 'MODAL.CONTACT.MAIL_PHONE.LASTNAME_ERROR' | translate }}
        </div>
      </div>
    </div>
    <div class="form-line">
      <div class="form-fieldset">
        <label for="email">{{
          'MODAL.CONTACT.MAIL_PHONE.EMAIL' | translate
        }}</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('email')?.invalid }"
          formControlName="email"
          type="email"
          class="form-control"
          id="email"
          [placeholder]="
            'MODAL.CONTACT.MAIL_PHONE.EMAIL_PLACEHOLDER' | translate
          "
        />
        <div
          *ngIf="form.get('email')?.invalid && !form.get('email')?.pristine"
          class="invalid-feedback"
        >
          {{ 'INVALID_EMAIL' | translate }}
        </div>
      </div>
      <div class="form-fieldset">
        <label for="phone">{{
          'MODAL.CONTACT.MAIL_PHONE.PHONE' | translate
        }}</label>
        <input
          [ngClass]="{ 'is-invalid': form.get('phone')?.invalid }"
          formControlName="phone"
          type="tel"
          class="form-control"
          id="phone"
          [placeholder]="
            'MODAL.CONTACT.MAIL_PHONE.PHONE_PLACEHOLDER' | translate
          "
        />
        <div
          *ngIf="form.get('phone')?.invalid && !form.get('phone')?.pristine"
          class="invalid-feedback"
        >
          {{ 'NUMBER_OUT_OF_RANGE' | translate }}
        </div>
      </div>
    </div>

    <div
      class="form-full"
      *ngIf="form.get('userType')?.value === 'professional'"
    >
      <label for="combien_de_vehicules_avez_vous_"
        >Combien de véhicules avez-vous ?</label
      >
      <input
        [ngClass]="{
          'is-invalid': form.get('combien_de_vehicules_avez_vous_')?.invalid
        }"
        formControlName="combien_de_vehicules_avez_vous_"
        type="number"
        class="form-control"
        id="combien_de_vehicules_avez_vous_"
        placeholder="1"
      />
    </div>
    <div
      class="form-full"
      *ngIf="form.get('userType')?.value === 'professional'"
    >
      <label for="comment_pouvons_nous_vous_aider__"
        >Comment pouvons-nous vous aider ?</label
      >
      <textarea
        [ngClass]="{
          'is-invalid': form.get('comment_pouvons_nous_vous_aider__')?.invalid
        }"
        formControlName="comment_pouvons_nous_vous_aider__"
        class="form-control"
        id="comment_pouvons_nous_vous_aider__"
        placeholder="Votre message"
      ></textarea>
    </div>

    <div class="rgpd-field">
      <mat-checkbox class="checkbox-rgpd" formControlName="rgpd">{{
        'MODAL.CONTACT.MAIL_PHONE.RGPD' | translate
      }}</mat-checkbox>
      <p
        class="test"
        [innerHTML]="'MODAL.CONTACT.MAIL_PHONE.RGPD_DETAILS' | translate"
      ></p>
    </div>

    <button
      *ngIf="currentState === state.PHONE"
      [disabled]="form.invalid"
      [lxfMatButton]
      type="submit"
      class="send-button"
    >
      {{ 'MODAL.CONTACT.MAIL_PHONE.SEND' | translate }}
    </button>
    <mat-spinner
      *ngIf="currentState === state.LOADING"
      [diameter]="getBreakPoints() <= BREAKPOINTS.SMALL ? 23 : 30"
      [strokeWidth]="getBreakPoints() <= BREAKPOINTS.SMALL ? 3 : 4"
    >
    </mat-spinner>
    <div class="error" *ngIf="globalError">
      {{ 'MODAL.CONTACT.MAIL_PHONE.GLOBAL_ERROR' | translate }}
    </div>
  </form>
</div>
