<div class="pannel-container" [ngClass]="{ hidden: isHidden }">
  <div class="pannel">
    <div class="panel-title">
      <h2 *ngIf="type === 'cf'">Inscription du chargé d'affaires</h2>
      <h2 *ngIf="type === 'lead'">Transférez une opportunité à Movivolt</h2>
    </div>
    <br />
    <div class="pannel-content">
      <div *ngIf="type === 'cf'">
        <br />
        <p>
          Inscrivez-vous à l'application Movivolt pour vous permettre de
          transférer des contacts à Movivolt en toute sécurité et fluidité,
          c'est simple et rapide et vous n'aurez plus à vous identifier dès la
          2ème connexion!
        </p>
        <br />
      </div>

      <div *ngIf="type === 'lead'">
        <p>
          Saisissez ici les informations de contact de votre client afin qu'il
          soit recontacté par Movivolt dans les meilleurs délais.
        </p>
        <br />
        <p>
          Une fois que les données de contact du client sont saisies, vous
          pouvez aussi prendre directement rendez-vous pour lui dans l'agenda du
          conseiller Movivolt en validant le formulaire, c'est un gain de temps
          pour votre client!
        </p>
        <br />
        <p>
          N'hésitez pas à détailler la zone ""offre proposée"" avec vos échanges
          sur le projet du client, toutes les informations sont bonnes à prendre
          pour le conseiller Movivolt qui aura accès à cette zone : le nombre de
          véhicules dans la flotte du client, leur type, les habitudes de
          financement du client..."
        </p>
        <br />
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
          "
        >
          <a routerLink="/form-cf-lbp">
            <button mat-raised-button color="primary">
              Pas encore inscrit ?
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
