<div class="state-init">
  <div class="header-img-container">
    <img
      loading="lazy"
      class="header-img"
      src="/public/img/round_contact.png"
      alt=""
    />
    <div class="ellipse"></div>
  </div>
  <div class="wave"></div>
  <div class="subtitle">{{ 'MODAL.CONTACT.INIT.SUBTITLE' | translate }}</div>
  <!-- <h1>{{ 'MODAL.CONTACT.INIT.TITLE' | translate }}</h1> -->
  <div class="phone">
    {{ 'MODAL.CONTACT.INIT.CALL_AT' | translate }}
    <a [href]="getPhoneLink()">{{
      'MODAL.CONTACT.INIT.PHONE_NUMBER' | translate
    }}</a>
  </div>
  <!-- <div class="mail">
    {{ 'MODAL.CONTACT.INIT.WRITE_AT' | translate }}
    <a [href]="getEmailLink()">{{ 'MODAL.CONTACT.INIT.MAIL' | translate }}</a>
  </div> -->
  <div class="or">
    <hr />
    <div>{{ 'MODAL.CONTACT.INIT.OR' | translate }}</div>
    <hr />
  </div>
  <!-- <div>{{ 'MODAL.CONTACT.INIT.CONTACT' | translate }}</div> -->
  <div class="buttonsContainer">
    <button [lxfMatButton]="buttonStyle.SECONDARY" (click)="goToRDV()">
      Prenez rendez-vous
      <div class="arrow"></div>
    </button>
    <button
      [lxfMatButton]="buttonStyle.SECONDARY"
      (click)="setState(state.PHONE)"
    >
      {{ 'MODAL.CONTACT.INIT.RECONTACT' | translate }}
      <div class="arrow"></div>
    </button>
  </div>
</div>
