
<div class="stepper-container" [ngClass]="{'currentRouteIsSignIn': currentRouteIsSignIn}">
  <mat-horizontal-stepper [linear]="true" [selectedIndex]="activeStep">
    <mat-step *ngFor="let label of steps; let index = index">
      <ng-template matStepLabel>{{ label }}</ng-template>
      <div *ngIf="index === 0">
        <formulaire-inscription-lbp
            [endPoint]="currentRouteIsSignIn ? '/new-cf-form' : '/new-lead-form'"
            [type]="currentRouteIsSignIn ? 'cf' : 'lead'"
            (handleContactFormSubmit)="handleContactFormSubmit()"
            ></formulaire-inscription-lbp>
      </div>
      <div *ngIf="index === 1">
        <formulaire-hubspot-meeting></formulaire-hubspot-meeting>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="buttons">
    <button
      *ngIf="activeStep === 0 && isContactFormSubmitted"
      mat-raised-button
      color="primary"
      (click)="reloadPage()"
    >
      Ne pas prendre de Rendez-vous quitter
    </button>
    <button
      *ngIf="activeStep === 0 && isContactFormSubmitted"
      mat-raised-button
      color="primary"
      (click)="handleNext()"
    >
      Continuer et Prendre un RDV
    </button>
  </div>
</div>
