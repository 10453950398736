import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

//TRANSITION
const enterTransition = transition(':enter', [
  style({
    opacity: '0',
    transform: 'translateY(-50%)',
  }),
  animate(
    '300ms ease-in',
    style({
      opacity: '1',
      transform: 'translateX(0)',
    }),
  ),
]);
const fadeIn = trigger('fadeIn', [enterTransition]);

export enum State {
  INIT = 'INIT',
  MAIL = 'MAIL',
  PHONE = 'PHONE',
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
}

@Component({
  selector: 'lxf-modal-devis',  
  animations: [fadeIn],
  templateUrl: './devis.component.html',  
  styleUrls: ['./devis.component.scss'],
})
export class DevisComponent {
  State = State;

  state: State = State.INIT;

  globalError = false;

  setState(newState: State): void {
    this.state = newState;
  }
}
