import { PostSliceType, CmsSliceType } from '.';

export interface SeoPage {
  meta_description: string;
  meta_image: ImageField;
}

export enum PrismicType {
  PAGE = 'page',
  BLOG_ARTICLE = 'blog_article',
  SERVICE = 'service',
}

export interface BaseHttp {
  results_per_page: number;
  page: number;
  next_page: number | null;
  prev_page: number | null;
  results_size: number;
  total_pages: number;
  total_results_size: number;
  version: string;
}

export interface Slice {
  slice_type: CmsSliceType | PostSliceType;
  primary: any;
  items: Array<any>;
}

export interface PrismicRef {
  id: string;
  ref: string;
  label: string;
  isMasterRef: boolean;
  tags: Array<string>;
}

export enum TypeField {
  PARAGRAPH = 'paragraph',
  LIST_ITEM = 'list-item',
}

export enum SpanType {
  STRONG = 'strong',
  LINK = 'hyperlink',
}

export interface EndPoint {
  refs: Array<PrismicRef>;
}

export interface Span {
  start: number;
  end: number;
  type: SpanType;
  data?: {
    link_type: string;
    url: string;
  };
  text?: string;
}

export interface ImageFieldDimension {
  width: number;
  height: number;
}

export interface LinkField {
  link_type: string;
  url: string;
}

export interface ParagraphField {
  type: TypeField;
  text: string;
  spans?: Array<Span>;
}

export interface ImageField {
  alt: string;
  copyright: string;
  dimensions: ImageFieldDimension;
  url: string;
  responsive?: ImageField;
}

export interface RelationshipField {
  id: string;
  isBroken: boolean;
}
