import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { Observable, of, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransferHttpService {
  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient,
  ) {}

  get<T>(url: string, options?: any): Observable<T> {
    const key = this.initCacheKey(url, options);

    if (this.transferState.hasKey(key)) {
      return of(this.transferState.get(key, null));
    }
    return this.httpClient.get(url, options).pipe(
      catchError(() => {
        this.transferState.set(key, undefined);
        return of(undefined);
      }),
      tap((data: any) => {
        this.transferState.set(key, data);
      }),
    );
  }

  initCacheKey(url: string, options?: any): StateKey<any> {
    const optionsString = options ? JSON.stringify(options) : '';
    return makeStateKey(`${url + optionsString}`);
  }
}
