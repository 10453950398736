export const NAV = {
  HOME: () => `/`,
  WHO_ARE_WE: () => `/page/qui-sommes-nous`,
  WHO_ARE_WE_REWORK: () => `/qui-sommes-nous`,
  REWORK_WHO_ARE_WE: () => `/page/rework-who-are-we`,
  A11Y: () => `/page/accessibilite`,
  LEGAL_NOTICE: () => `/page/mentions-legales`,
  PROTECTION_OF_PERSONAL_DATA: () =>
    `/page/protection-des-donnees-personnelles`,
  BLOG_MAIN: () => `/blog`,
  // USE FOR HEADER DARK MODE
  HIDDEN_BLOG_MAIN: () => `/page/blog`,
  BLOG_ARTICLE: (slug: string) => `/blog/${slug}`,
  CATALOG_MAIN: () => `/catalogue`,
  CATALOG_DETAIL: (id: string) => `/catalogue/${id}`,
  SERVICES_MAIN: () => `/services`,
  RESAH: () => '/page/resah',
  SERVICE_ARTICLE: (slug: string) => `/service/${slug}`,
  CATALOG: () => `/page/catalogue`,
  CATALOG_CATEGORIES: (slug?: string) => `/page/catalogue/${slug}`,
  SERVICES: () => '/page/services',
  NOT_FOUND: () => '/public/404',
  ERROR: () => '/public/500',
};

export const SLICE_ID = {
  WE_ACCOMPANY_YOU: `nous-vous-accompagnons`,
  ADVANTAGES: `avantages`,
  CONTACT: 'contact',
};

export const ANCHOR = {
  TOP: `top`,
};
