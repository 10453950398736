export enum Tracking {
  CONTACT_FILLING_BY_MAIL = 'Popin Contact - Remplissage du formulaire contact par mail démarré',
  CONTACT_FILLING_BY_PHONE = 'Popin Contact - Remplissage du formulaire contact par téléphone démarré',
  CTA_HEADER_REQUEST_MEETING = 'Header - CTA - Prendre rendez-vous',
  PREJUDICE_OPEN = 'Préjugés - Ouvre une boite',
  CONTACT_EXPERT_AUTONOMY = 'Autonomie - CTA - contacter nos experts',
  NAV_BAR_CTA = 'NavBar - CTA - ',
  PAGE_VISITED = ' - Page visitée',
  BLOG_SHARED_SOCIAL_NETWORK = 'Blog - Détail Article - Partager sur les réseaux',
  BLOG_RECOMMENDED_ARTICLE = 'Blog - Détail Article - Lire une recommandation',
  BLOG_READ_ARTICLE = 'Blog - Listing Articles - Lire un article',
  BLOG_FILTER_TAG = 'Blog - Listing Articles - Filtre sélectionné',
  BLOG_POST_SPOTLIGHT = 'Blog - Listing Articles - Lire un article à la une ',
}

export enum TrackingPropertyName {
  BOX_NUMBER = 'Numéro de boite',
  SOCIAL_NETWORK = 'Nom du réseau',
  SLUG_ARTICLE = "ID de l'article",
  FILTER_LABEL = 'Nom du filtre',
}

export enum GTM_Tracking {
  CONTACT_SEND_BY_MAIL_OK = 'form_contact_submit_by_mail_ok',
  CONTACT_SEND_BY_PHONE_OK = 'form_contact_submit_by_phone_ok',
  CONTACT_SEND_BY_MAIL_KO = 'form_contact_submit_by_mail_ko',
  CONTACT_SEND_BY_PHONE_KO = 'form_contact_submit_by_phone_ko',
}
