import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutFooterComponent } from 'src/app/layout/components/footer/footer.component';
import { LayoutHeaderComponent } from 'src/app/layout/components/header/header.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [LayoutFooterComponent, LayoutHeaderComponent],
  exports: [LayoutFooterComponent, LayoutHeaderComponent],
})
export class LayoutModule {}
