import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

import { UiModule } from 'src/app/shared/components/ui/ui.module';
import { PipeModule } from 'src/app/shared/pipe/pipe.module';

import { DirectiveModule } from './directives/directive.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressBarModule,
    PipeModule,
    UiModule,
    MatButtonModule,
    MatExpansionModule,
    DirectiveModule,
  ],
  exports: [PipeModule, UiModule, MatExpansionModule, DirectiveModule],
})
export class SharedModule {}
