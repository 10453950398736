import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { NAV } from 'src/app/core/NAV';

@Component({
  selector: 'lxf-redirect',
  template: '',
})
export class RedirectComponent {
  readonly BLOG_PATH = '/blog';
  readonly CATALOG_PATH = '/catalogue';
  readonly SERVICES_PATH = '/services';
  readonly RESAH_PATH = '/resah';

  constructor(private router: Router) {
    switch (this.router.url) {
      case this.BLOG_PATH:
        this.router.navigateByUrl(NAV.HIDDEN_BLOG_MAIN(), {
          skipLocationChange: true,
        });
        break;
      case this.CATALOG_PATH:
        this.router.navigateByUrl(NAV.CATALOG(), {
          skipLocationChange: true,
        });
        break;
      case this.SERVICES_PATH:
        this.router.navigateByUrl(NAV.SERVICES(), {
          skipLocationChange: true,
        });
        break;
      case this.RESAH_PATH:
        this.router.navigateByUrl(NAV.RESAH(), {
          skipLocationChange: true,
        });
        break;
    }
  }
}
