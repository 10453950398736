import { Component, Input } from '@angular/core';

@Component({
  selector: 'lxf-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() subTitle = '';

  @Input() darkMode = false;
}
