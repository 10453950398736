import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HTTP } from 'src/app/core/HTTP';
import { CmsPageHttp, PrismicRef, CmsPageData } from 'src/app/core/models';
import { PrismicService } from 'src/app/core/services/prismic.service';
import { TransferHttpService } from 'src/app/core/services/transfer-http.service';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  public prismicRef: PrismicRef | undefined;

  constructor(
    private transferHttpService: TransferHttpService,
    private prismicService: PrismicService,
  ) {}

  getPage(slug: string): Observable<CmsPageData> {
    return this.prismicService.getLastRef().pipe(
      switchMap((prismicRef: PrismicRef) => {
        return this.transferHttpService.get<CmsPageHttp>(
          HTTP.CMS.PAGE(prismicRef.ref ?? '', slug),
        );
      }),
      map((data: CmsPageHttp) => {
        return data?.results[0];
      }),
    );
  }
}
