<div [ngClass]="'hubspot-form-bloc' + (isFormSubmitted ? ' submitted' : '')">
    <ng-container *ngIf="isFormSubmitted; else formNotSubmitted">
      <submission-form-panel [type]="'confirm'" [subEvent]="formType + '-submission'"></submission-form-panel>
    </ng-container>
    <ng-template #formNotSubmitted>
      <text-panel [isHidden]="isFormSubmitted" [type]="formType"></text-panel>
      <div class="hubspot-form-container">
        <div id="hubspot-form-container-frame"></div>
      </div>
    </ng-template>
  </div>
  