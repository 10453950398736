import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private activeStepSubject = new BehaviorSubject<number>(0);
  private isContactFormSubmittedSubject = new BehaviorSubject<boolean>(false);

  activeStep$: Observable<number> = this.activeStepSubject.asObservable();
  isContactFormSubmitted$: Observable<boolean> =
    this.isContactFormSubmittedSubject.asObservable();

  handleContactFormSubmit() {
    this.isContactFormSubmittedSubject.next(true);
  }

  handleNext() {
    this.activeStepSubject.next(this.activeStepSubject.value + 1);
  }

  handleBack() {
    if (this.activeStepSubject.value > 0) {
      this.isContactFormSubmittedSubject.next(false);
      this.activeStepSubject.next(this.activeStepSubject.value - 1);
    }
  }
}
