import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public previousUrl?: string;

  constructor(readonly router: Router, readonly _location: Location) {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        map((event) => event as RoutesRecognized),
      )
      .subscribe((event) => {
        this.previousUrl = event.urlAfterRedirects;
      });
  }

  public back(basicUrl: string): void {
    if (this.previousUrl !== undefined) {
      this._location.back();
    } else {
      this.router.navigate([basicUrl], { replaceUrl: true });
    }
  }
}
