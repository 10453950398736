<footer class="container-fluid" role="contentinfo">
  <div
    class="container d-flex flex-column flex-lg-row justify-content-between align-items-lg-center"
  >
    <div class="text-infos">
      <lxf-logo></lxf-logo>
      <div class="s-social-networks">
        <a
          target="_blank"
          rel="noopener"
          href="https://www.facebook.com/Movivolt-100528775822082"
        >
          <lxf-social-network-facebook
            [size]="24"
          ></lxf-social-network-facebook>
        </a>
        <a
          target="_blank"
          rel="noopener"
          href="https://www.youtube.com/channel/UCcOo3Lv8_NF7MeYxyz1yF7w"
        >
          <lxf-social-network-youtube [size]="24"></lxf-social-network-youtube>
        </a>
        <a
          target="_blank"
          rel="noopener"
          href="https://www.linkedin.com/company/movivolt/"
        >
          <lxf-social-network-linkedin
            [size]="24"
          ></lxf-social-network-linkedin>
        </a>
      </div>
    </div>

    <div class="flex-grow-1"></div>

    <nav
      *ngIf="menu"
      aria-label="Footer navigation principal"
      class="d-flex flex-column flex-lg-row"
    >
      <a
        [routerLink]="item.url"
        [fragment]="item.anchor"
        *ngFor="let item of menu.items; let last = last"
        [ngClass]="{ 'me-lg-0': last }"
        >{{ item.label | translate }} <lxf-arrow [size]="9"></lxf-arrow
      ></a>
    </nav>

    <div class="s-separator d-none d-lg-block mx-5"></div>
  </div>

  <div class="container">
    <hr />
  </div>

  <div
    class="container d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center"
  >
    <nav
      aria-label="Footer navigation secondaire"
      class="s-sub-links d-flex flex-column flex-lg-row order-0 order-lg-1"
    >
      <a (click)="manageCookie()"
        >{{ 'LAYOUT.FOOTER.MANAGE_COOKIES' | translate }}
        <lxf-arrow [size]="9"></lxf-arrow
      ></a>
      <a
        *ngFor="let link of subLinks; let last = last"
        [routerLink]="link.link"
        [ngClass]="{ 'me-lg-0': last }"
        >{{ link.label }} <lxf-arrow [size]="9"></lxf-arrow
      ></a>
    </nav>
    <hr class="d-block d-lg-none" />
    <div class="s-copy order-1 order-lg-0 d-flex">
      <a
        target="_blank"
        rel="noopener"
        href="https://www.banquedesterritoires.fr/"
      >
        <img
          loading="lazy"
          height="52"
          width="100"
          src="/public/img/logo_caisse_depot.png"
          alt="logo de la caisse des dépôts"
        />
      </a>
      <a target="_blank" rel="noopener" href="https://www.groupelaposte.com/fr">
        <img
          loading="lazy"
          height="40"
          width="60"
          src="/public/img/logo_laposte.png"
          alt="logo de la poste"
        />
      </a>
    </div>
  </div>
  <div class="container">
    <hr />
  </div>
  <div class="container s-mentions">
    <div class="s-copy">
      {{ 'LAYOUT.FOOTER.COPYRIGHT' | translate: { currentYear: currentYear } }}
      {{ 'LAYOUT.FOOTER.PROJECT_NAME' | translate }}
    </div>
    <ol>
      <li [innerHTML]="'LAYOUT.FOOTER.MENTIONS' | translate"></li>
    </ol>
  </div>
</footer>
