import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscriber } from 'rxjs';

import {
  BREAKPOINTS,
  ButtonStyle,
  GTM_Tracking,
  Tracking,
} from 'src/app/core/models';
import { CrmService } from 'src/app/core/services/crm.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { UtilsService } from 'src/app/core/services/utils.service';

import { State } from '../../contact.component';

@Component({
  selector: 'lxf-modal-contact-mail',
  templateUrl: './contact-mail.component.html',
  styleUrls: ['./contact-mail.component.scss'],
})
export class ContactMailComponent implements OnInit, OnDestroy {
  buttonStyle: typeof ButtonStyle = ButtonStyle;
  state: typeof State = State;
  currentState: State = State.MAIL;
  BREAKPOINTS = BREAKPOINTS;

  @Output() changeState = new EventEmitter<State>();

  subscriber = new Subscriber();

  globalError = false;

  formFilled = false;
  form = new UntypedFormGroup({
    lastname: new UntypedFormControl('', [Validators.required]),
    firstname: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    rgpd: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private trackingService: TrackingService,
    private crmService: CrmService,
    private translateService: TranslateService,
    private gtmService: GoogleTagManagerService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.subscriber.add(
      this.form.valueChanges.subscribe(() => {
        if (!this.formFilled) {
          this.trackingService.sendEvent(Tracking.CONTACT_FILLING_BY_MAIL);
        }
        this.formFilled = true;
      }),
    );
  }

  setState(newState: State): void {
    this.changeState.emit(newState);
  }

  getBreakPoints(): number {
    return this.utilsService.getBreakPoints();
  }

  sendForm(): void {
    this.globalError = false;
    this.currentState = State.LOADING;

    if (this.form.valid) {
      this.crmService
        .contactMailSubmission({
          submittedAt: new Date().getTime(),
          fields: [
            {
              name: 'lastname',
              value: this.form.get('lastname')?.value ?? '',
            },
            {
              name: 'firstname',
              value: this.form.get('firstname')?.value ?? '',
            },
            {
              name: 'email',
              value: this.form.get('email')?.value ?? '',
            },
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: this.translateService.instant('MODAL.CONTACT.RGPD'),
            },
          },
        })
        .subscribe({
          next: () => {
            this.gtmService.pushTag({
              event: GTM_Tracking.CONTACT_SEND_BY_MAIL_OK,
            });
            this.setState(State.COMPLETE);
          },
          error: () => {
            this.globalError = true;
            this.gtmService.pushTag({
              event: GTM_Tracking.CONTACT_SEND_BY_MAIL_KO,
            });
            this.currentState = State.MAIL;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
