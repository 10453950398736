<div class="modal-container">
  <div mat-dialog-close class="close"></div>
  <div class="contact-container">
    <ng-container *ngIf="state === State.INIT">
      <lxf-init-devis (changeState)="setState($event)" @fadeIn>
      </lxf-init-devis>
    </ng-container>
    <ng-container *ngIf="state === State.COMPLETE">
      <lxf-modal-devis-complete @fadeIn> </lxf-modal-devis-complete>
    </ng-container>
  </div>
</div>
