import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';

import { LayoutMenu } from 'src/app/core/models';
import { ANCHOR, NAV } from 'src/app/core/NAV';
import { LayoutService } from 'src/app/layout/services/layout.service';

@Component({
  selector: 'lxf-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  menu: LayoutMenu | undefined;

  subLinks = [
    { link: NAV.A11Y(), label: 'Accessibilité', anchor: ANCHOR.TOP },
    {
      link: NAV.LEGAL_NOTICE(),
      label: 'Mentions légales',
      anchor: ANCHOR.TOP,
    },
    {
      link: NAV.PROTECTION_OF_PERSONAL_DATA(),
      label: 'Protection des données personnelles',
      anchor: ANCHOR.TOP,
    },
  ];

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.menu = this.layoutService.getMenu();
  }

  manageCookie(): void {
    if (isPlatformBrowser(this.platformId)) {
      window?.tC?.privacyCenter?.showPrivacyCenter();
    }
  }
}
