import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { HTTP } from 'src/app/core/HTTP';
import { CmsPageHttp, EndPoint, PrismicRef } from 'src/app/core/models';
import { TransferHttpService } from 'src/app/core/services/transfer-http.service';

const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class PrismicService {
  public prismicRef: PrismicRef | undefined;
  private cachePrismicRef$: Observable<PrismicRef> | undefined;
  constructor(private transferHttpService: TransferHttpService) {}

  getLastRef(): Observable<PrismicRef> {
    if (!this.cachePrismicRef$) {
      this.cachePrismicRef$ = this.transferHttpService
        .get<EndPoint>(HTTP.CMS.REF())
        .pipe(
          map((data: EndPoint) => {
            return data?.refs.filter((prismicRef) => prismicRef.isMasterRef)[0];
          }),
          tap((prismicRef) => (this.prismicRef = prismicRef)),
          shareReplay(CACHE_SIZE),
        );
    }
    return this.cachePrismicRef$;
  }

  getPreviewContent(
    documentId: string,
    token: string,
  ): Observable<CmsPageHttp> {
    return this.transferHttpService.get<CmsPageHttp>(
      HTTP.CMS.PREVIEW(token, documentId),
    );
  }
}
