import { CmsVehicleType } from './cms';

export const DEFAULT_IMG = '/public/img/no_car.png';

export enum CatalogVehicleCharacteristicCategory {
  GENERAL = 'Général',
  ENGINE = 'Moteur',
  BATTERY = 'Batterie',
  DIMENSIONS = 'Dimensions',
  WEIGHT = 'Poids et charge',
  PERFORMANCE = 'Performances',
  AUTONOMY = 'Autonomie',
  STANDARD_EQUIPMENT = 'Equipements de série',
}

export interface CatalogVehicle {
  id: string;
  modelType: CmsVehicleType;
  mainImage: string;
  brand: string;
  modelName: string;
  subModel: string;
  priceWithoutTax?: number;
  priceWithTax?: number;
  duration: number;
  kilometer: number;
  autonomy: string;
  tags: string[];
}

export interface CatalogVehicleDetail {
  id: string;
  modelType: CmsVehicleType;
  mainImage: string;
  brand: string;
  modelName: string;
  subModel: string;
  priceWithoutTax?: number;
  priceWithTax?: number;
  duration: number;
  kilometer: number;
  autonomy: string;
  tags: string[];
  secondaryImages: Array<string>;
  characteristics: Array<CatalogVehicleCharacteristic>;
}

export interface CatalogVehicleDetailHeader {
  mainImage?: string;
  brand?: string;
  modelName?: string;
}

export interface CatalogVehicleCharacteristic {
  category: CatalogVehicleCharacteristicCategory;
  label: string;
  value: string;
  unit?: string;
}

export interface CatalogVehicleCategory {
  category: CatalogVehicleCharacteristicCategory;
  characteristics: Array<CatalogVehicleCharacteristic>;
}
