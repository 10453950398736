import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Angulartics2Mixpanel } from 'angulartics2';

import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public readonly COOKIE_TECHNICAL = '1';
  public readonly COOKIE_ANALYSE = '2';
  public readonly COOKIE_PERSO = '3';
  public readonly COOKIE_ADS = '4';

  public readonly TRUST_COMMANDER_SCRIPT =
    'https://cdn.trustcommander.net/privacy/6214/privacy_v2_2.js';
  public readonly MIXPANEL_SCRIPT = '/public/js/mixpanel.js';

  oldValidCategories: Array<string> = [];
  listenerFrameHubspotIsInit = false;

  constructor(
    private angulartics2Mixpanel: Angulartics2Mixpanel,
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) {}

  addCookieScript(): void {
    if (isPlatformBrowser(this.platformId)) {
      const banner = document.createElement('script');
      banner.src = this.TRUST_COMMANDER_SCRIPT;
      banner.addEventListener('load', () => {
        this.oldValidCategories = this.getValidCategories();
        this.initCookies();

        window?.tC?.eventTarget?.addEventListener(
          'consent-update',
          (e: any) => {
            this.reload();
          },
        );
      });
      document.body.appendChild(banner);
    }
  }

  initCookies(): void {
    if (this.getValidCategories().includes(this.COOKIE_ANALYSE)) {
      this.initMixPanel();
      this.initListennerFormFrameHubspot();
    }
    if (this.getValidCategories().includes(this.COOKIE_ADS)) {
      this.gtmService.addGtmToDom();
    }
  }

  // Google Tag Manager : Event sent when hubspot form (iframe) is submitted
  private initListennerFormFrameHubspot(): void {
    if (!this.listenerFrameHubspotIsInit) {
      window.addEventListener('message', function (event) {
        if (event.data.meetingBookSucceeded) {
          // @ts-ignore load by GTM script
          dataLayer.push({ event: 'rdv_hubspot_ok' });
        }
        if (event.data.meetingBookFailed) {
          // @ts-ignore load by GTM script
          dataLayer.push({ event: 'rdv_hubspot_ko' });
        }
      });
    }
    this.listenerFrameHubspotIsInit = true;
  }

  initMixPanel(): void {
    if (environment.production) {
      const mixPanelScript = document.createElement('script');
      mixPanelScript.src = this.MIXPANEL_SCRIPT;
      mixPanelScript.addEventListener('load', () => {
        window?.mixpanel.init(environment.mixpanel);
        this.angulartics2Mixpanel.startTracking();
      });
      document.body.appendChild(mixPanelScript);
    }
  }

  reload(): void {
    window.location.reload();
  }

  getValidCategories(): Array<string> {
    return window?.tC?.privacy?.getValidCategories();
  }
}
