import { ImageField, ParagraphField, Slice } from './prismic';

export enum PostSliceType {
  INTRODUCTION = 'introduction',
  TEXT = 'texte',
  IMAGE_SIMPLE = 'image_simple',
  GOOD_TO_KNOW = 'bon_a_savoir',
  IMAGE_DOUBLE = 'image_double',
  QUOTATION = 'devis',
}

export interface PostAuteur {
  data: {
    nom: Array<ParagraphField>;
    prenom?: Array<ParagraphField>;
    profil: ImageField;
  };
}

export interface PostSliceIntroduction extends Slice {
  primary: {
    contenu: Array<ParagraphField>;
  };
}

export interface PostSliceText extends Slice {
  primary: {
    contenu: Array<ParagraphField>;
  };
}
export interface PostSliceImageSimple extends Slice {
  primary: {
    image: ImageField;
  };
}

export interface PostSliceGoodToKnow extends Slice {
  primary: {
    titre1: Array<ParagraphField>;
    contenu: Array<ParagraphField>;
  };
}

export interface PostSliceImageDouble extends Slice {
  primary: {
    image1: ImageField;
    image2: ImageField;
  };
}

export interface PostSliceQuotation extends Slice {
  primary: {
    catalogue_id: Array<ParagraphField>;
    texte: Array<ParagraphField>;
  };
}
