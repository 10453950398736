import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscriber } from 'rxjs';

import {
  BREAKPOINTS,
  ButtonStyle,
  GTM_Tracking,
  Tracking,
} from 'src/app/core/models';
import { CrmService } from 'src/app/core/services/crm.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { UtilsService } from 'src/app/core/services/utils.service';

import { State } from '../../contact.component';

@Component({
  selector: 'lxf-modal-contact-phone',
  templateUrl: './contact-phone.component.html',
  styleUrls: ['./contact-phone.component.scss'],
})
export class ContactPhoneComponent implements OnInit, OnDestroy {
  buttonStyle: typeof ButtonStyle = ButtonStyle;
  state: typeof State = State;
  currentState: State = State.PHONE;
  BREAKPOINTS = BREAKPOINTS;

  @Output() changeState = new EventEmitter<State>();

  subscriber = new Subscriber();

  globalError = false;

  formFilled = false;
  form = new UntypedFormGroup({
    userType: new UntypedFormControl('professional', [Validators.required]),
    name: new UntypedFormControl('', this.companyValidator()),
    siret: new UntypedFormControl(''),
    combien_de_vehicules_avez_vous_: new UntypedFormControl(''),
    comment_pouvons_nous_vous_aider__: new UntypedFormControl(''),
    lastname: new UntypedFormControl('', [Validators.required]),
    firstname: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^(?:\\s*\\d){10}$'),
    ]),
    rgpd: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private trackingService: TrackingService,
    private crmService: CrmService,
    private translateService: TranslateService,
    private gtmService: GoogleTagManagerService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    this.subscriber.add(
      this.form.valueChanges.subscribe(() => {
        if (!this.formFilled) {
          this.trackingService.sendEvent(Tracking.CONTACT_FILLING_BY_PHONE);
        }
        this.formFilled = true;
      }),
    );

    this.form.get('userType')?.valueChanges.subscribe(() => {
      this.updateCompanyValidator();
    });
  }

  

  companyValidator() {
    if(this.form) {
      return (control: AbstractControl) => {
        if (this.form.get('userType')?.value === 'professional') {
          return Validators.required(control); // Si userType est égal à "professional", le champ company est requis
        } else {
          return null; // Sinon, le champ company n'est pas requis
        }
      };
    }
    return null
  }

  updateCompanyValidator() {
    const companyControl = this.form.get('name');
    companyControl?.clearValidators(); // Supprimer les validateurs actuels
    companyControl?.updateValueAndValidity(); // Mettre à jour la valeur et la validité

    const newValidators = this.companyValidator(); // Obtenir les nouveaux validateurs en fonction de la valeur de userType
    companyControl?.setValidators(newValidators); // Définir les nouveaux validateurs
    companyControl?.updateValueAndValidity(); // Mettre à jour la valeur et la validité
  }

  setState(newState: State): void {
    this.changeState.emit(newState);
  }

  getBreakPoints(): number {
    return this.utilsService.getBreakPoints();
  }

  sendForm(): void {
    this.globalError = false;
    this.currentState = State.LOADING;
    if (this.form.valid) {
      const fields = [
        {
          name: 'lastname',
          value: this.form.get('lastname')?.value ?? '',
        },
        {
          name: 'firstname',
          value: this.form.get('firstname')?.value ?? '',
        },
        {
          name: 'phone',
          value: this.form.get('phone')?.value.replace(/^0/, '+33') ?? '',
        },
        {
          name: 'email',
          value: this.form.get('email')?.value ?? '',
        },
        {
          name: '0-2/name',
          value: this.form.get('name')?.value ?? '',
        },
        {
          name: 'siret',
          value: this.form.get('siret')?.value ?? '',
        },
        {
          name: 'combien_de_vehicules_avez_vous_',
          value: this.form.get('combien_de_vehicules_avez_vous_')?.value ?? '',
        },
        {
          name: 'comment_pouvons_nous_vous_aider__',
          value: this.form.get('comment_pouvons_nous_vous_aider__')?.value ?? '',
        },
        {
          name: 'vous_etes',
          value: this.form.get('userType')?.value === 'professional' ? 'Un professionnel' : 
            this.form.get('userType')?.value === 'individual' ? 'Un particulier' : '',
        }
      ];
    
      // Soumettre les champs avec les données pertinentes
      this.crmService
        .contactPhoneSubmission({
          submittedAt: new Date().getTime(),
          fields: fields,
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: this.translateService.instant('MODAL.CONTACT.RGPD'),
            },
          },
        })
        .subscribe({
          next: () => {
            this.gtmService.pushTag({
              event: GTM_Tracking.CONTACT_SEND_BY_PHONE_OK,
            });
            this.setState(State.COMPLETE);
          },
          error: () => {
            this.globalError = true;
            this.gtmService.pushTag({
              event: GTM_Tracking.CONTACT_SEND_BY_PHONE_KO,
            });
            this.currentState = State.PHONE;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
