<svg
  [attr.height]="size"
  [attr.width]="size"
  viewBox="0 0 38 38"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle opacity=".5" cx="19" cy="19" r="19" fill="#B9C7DB" />
  <path
    d="M30.875 19.073c0-6.599-5.317-11.948-11.875-11.948S7.125 12.475 7.125 19.073c0 5.963 4.342 10.906 10.02 11.802v-8.349h-3.016v-3.453h3.016V16.44c0-2.994 1.772-4.648 4.485-4.648 1.299 0 2.658.233 2.658.233v2.94h-1.497c-1.475 0-1.936.922-1.936 1.867v2.24h3.294l-.527 3.454h-2.767v8.349c5.677-.896 10.02-5.84 10.02-11.802z"
    fill="#001E51"
  />
</svg>
